import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xl-8 col-lg-10 col-md-12 m-auto" }
const _hoisted_6 = { class: "login_wrap widget-taber-content background-white" }
const _hoisted_7 = { class: "padding_eight_all bg-white p-15" }
const _hoisted_8 = { class: "heading_s1" }
const _hoisted_9 = { class: "mb-5" }
const _hoisted_10 = { class: "mb-30" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = ["type", "placeholder"]
const _hoisted_20 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = ["type", "placeholder"]
const _hoisted_23 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_24 = { class: "chek-form" }
const _hoisted_25 = { class: "custome-checkbox" }
const _hoisted_26 = {
  class: "form-check-label",
  for: "exampleCheckbox1"
}
const _hoisted_27 = {
  key: 0,
  class: "chek-form"
}
const _hoisted_28 = { class: "custome-checkbox" }
const _hoisted_29 = {
  class: "form-check-label",
  for: "exampleCheckbox1"
}
const _hoisted_30 = {
  key: 1,
  class: "form-group"
}
const _hoisted_31 = ["placeholder"]
const _hoisted_32 = { class: "form-group mb-30" }
const _hoisted_33 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.t("signUp.createAnAccount")), 1),
                          _createElementVNode("p", _hoisted_10, [
                            _createTextVNode(_toDisplayString(_ctx.t("signUp.alreadyHaveAccount")) + " ", 1),
                            _createElementVNode("a", {
                              href: "javascript:void(0)",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navSignIn()))
                            }, _toDisplayString(_ctx.t("signUp.loginIn")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signUp.firstname) = $event)),
                            class: _normalizeClass(["form-control", {
                        'is-invalid': _ctx.vSignUp.firstname.$errors.length,
                      }]),
                            type: "text",
                            autocomplete: "given-name",
                            placeholder: _ctx.t('signUp.firstName')
                          }, null, 10, _hoisted_12), [
                            [_vModelText, _ctx.signUp.firstname]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.signUp.lastname) = $event)),
                            type: "text",
                            class: _normalizeClass(["form-control", {
                        'is-invalid': _ctx.vSignUp.lastname.$errors.length,
                      }]),
                            autocomplete: "family-name",
                            placeholder: _ctx.t('signUp.lastName')
                          }, null, 10, _hoisted_14), [
                            [_vModelText, _ctx.signUp.lastname]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.signUp.email) = $event)),
                            type: "text",
                            class: _normalizeClass(["form-control", {
                        'is-invalid': _ctx.vSignUp.email.$errors.length,
                      }]),
                            autocomplete: "email",
                            placeholder: _ctx.t('signUp.email')
                          }, null, 10, _hoisted_16), [
                            [_vModelText, _ctx.signUp.email]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_vue_tel_input, {
                            onInput: _ctx.onTelInput,
                            class: _normalizeClass(["form-control", {
  'is-invalid': _ctx.vSignUp.mobileNo.$errors.length,
}]),
                            mode: "international",
                            defaultCountry: _ctx.countryCode,
                            inputOptions: {
                        placeholder: _ctx.t('signUp.mobile'),
                      }
                          }, null, 8, ["onInput", "defaultCountry", "inputOptions", "class"])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.signUp.password) = $event)),
                            type: !_ctx.signUp.showPassword ? 'password' : 'text',
                            class: _normalizeClass(["form-control", {
                        'is-invalid': _ctx.vSignUp.password.$errors.length,
                      }]),
                            autocomplete: "password",
                            placeholder: _ctx.t('signUp.password')
                          }, null, 10, _hoisted_19), [
                            [_vModelDynamic, _ctx.signUp.password]
                          ]),
                          (_ctx.vSignUp.password.$errors.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.t("validations.minLength")), 1))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_button, {
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.signUp.showPassword = !_ctx.signUp.showPassword)),
                            fill: "clear",
                            slot: "end",
                            class: "endIconBtn"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: !_ctx.signUp.showPassword
                          ? _ctx.icons.eyeOutline
                          : _ctx.icons.eyeOffOutline
                          
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.signUp.confirmation) = $event)),
                            type: !_ctx.signUp.showPassword ? 'password' : 'text',
                            class: _normalizeClass(["form-control", {
                        'is-invalid': _ctx.vSignUp.confirmation.$errors.length,
                      }]),
                            autocomplete: "password",
                            placeholder: _ctx.t('signUp.confirmPassword')
                          }, null, 10, _hoisted_22), [
                            [_vModelDynamic, _ctx.signUp.confirmation]
                          ]),
                          (_ctx.vSignUp.confirmation.$errors.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.t("validations.sameAsPassword")), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_ion_checkbox, {
                              onIonChange: _cache[7] || (_cache[7] = ($event: any) => (
                          _ctx.signUp.accountfield.asked_wholesale =
                          !_ctx.signUp.accountfield.asked_wholesale
                          )),
                              value: 1,
                              checked: _ctx.signUp.accountfield.asked_wholesale
                            }, null, 8, ["checked"]),
                            _createElementVNode("label", _hoisted_26, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.t("signUp.isWholesale")), 1)
                            ])
                          ])
                        ]),
                        (_ctx.signUp.accountfield.asked_wholesale)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createVNode(_component_ion_checkbox, {
                                  onIonChange: _cache[8] || (_cache[8] = ($event: any) => (
                          _ctx.signUp.accountfield.know_wholesale_code =
                          !_ctx.signUp.accountfield.know_wholesale_code
                          )),
                                  value: 1,
                                  checked: _ctx.signUp.accountfield.know_wholesale_code
                                }, null, 8, ["checked"]),
                                _createElementVNode("label", _hoisted_29, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.t("signUp.knowWholesaleCode")), 1)
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.signUp.accountfield.asked_wholesale &&
                        _ctx.signUp.accountfield.know_wholesale_code
                        )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.signUp.accountfield.wholesale_code) = $event)),
                                type: "text",
                                class: _normalizeClass(["form-control", {
                        'is-invalid':
                          _ctx.vSignUp.accountfield.wholesale_code.$errors.length,
                      }]),
                                autocomplete: "wholesale_code",
                                placeholder: _ctx.t('signUp.wholesaleCode')
                              }, null, 10, _hoisted_31), [
                                [_vModelText, _ctx.signUp.accountfield.wholesale_code]
                              ]),
                              _createVNode(_component_ion_button, {
                                href: _ctx.whatsAppLink,
                                target: "_blank",
                                class: "endIconBtn",
                                slot: "end",
                                fill: "clear"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.icons.logoWhatsapp,
                                    color: "green"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["href"]),
                              _createElementVNode("p", null, _toDisplayString(_ctx.t("signUp.getCode")), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("button", {
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.signUpAction($event))),
                            class: "btn btn-fill-out btn-block hover-up font-weight-bold",
                            name: "login"
                          }, _toDisplayString(_ctx.t("signUp.register")), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_33, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}