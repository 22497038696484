
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonCheckbox,
} from "@ionic/vue";
import { eyeOutline, eyeOffOutline, logoWhatsapp } from "ionicons/icons";
import { defineComponent, ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import sessionApp from "@/libs/session";

import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  sameAs,
  email,
  minLength,
} from "@vuelidate/validators";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

import { event as gtagEvent } from "vue-gtag";

export default defineComponent({
  name: "Login",
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const router = useRouter();
    const storeX = useStore();
    const { createLoading, openToast, registerDevice } = useGlobalFunctions();
    const { getLocalStorageData, setLocalStorageData } = useLocalStorage();

    // const allowedCountries = ref(
    //   getLocalStorageData("allowedCountries").split(",")
    // );

    const whatsAppNo = ref(getLocalStorageData("whatsAppNo"));
    const whatsAppLink = ref(
      "https://api.whatsapp.com/send/?phone=" + whatsAppNo.value
    );

    const icons = reactive({
      eyeOutline,
      eyeOffOutline,
      logoWhatsapp,
    });

    const domainOriginUrl = window.location.origin;
    const isAE = domainOriginUrl.includes("ae.yallatager");
    const countryCode = ref(isAE ? "AE" : "EG");

    const signUp = ref({
      firstname: "",
      lastname: "",
      mobileNo: "",
      email: "",
      mobileValidated: false,
      showPassword: false,
      password: "",
      confirmation: "",
      accountfield: {
        asked_wholesale: false,
        know_wholesale_code: false,
        wholesale_code: "",
      },
    });

    const signUpRules = computed(() => ({
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        email,
        required: requiredIf(() => {
          return getLocalStorageData("signupReuireEmail") == "1";
        }),
      },
      mobileNo: {
        required,
      },
      password: {
        required,
        minLength: minLength(7),
      },
      confirmation: {
        sameAsPassword: sameAs(signUp.value.password),
        required,
      },
      accountfield: {
        wholesale_code: {
          required: requiredIf(() => {
            return (
              signUp.value.accountfield.asked_wholesale &&
              signUp.value.accountfield.know_wholesale_code
            );
          }),
        },
      },
    }));
    const vSignUp = useVuelidate(signUpRules, signUp) as any;

    const onTelInput = (phone, phoneObject: any) => {
      if (phoneObject) {
        if (Object.prototype.hasOwnProperty.call(phoneObject, "number")) {
          signUp.value.mobileNo = phoneObject.number;
        }
        if (Object.prototype.hasOwnProperty.call(phoneObject, "valid")) {
          signUp.value.mobileValidated = phoneObject.valid;
        }
      }
    };

    const signUpAction = async (event) => {
      event.preventDefault();
      vSignUp.value.$touch();
      if (vSignUp.value.$invalid) {
        return false;
      }
      if (!signUp.value.mobileValidated) {
        return false;
      }

      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("register"), signUp.value)
        .then((response: any) => {
          if (response.success) {
            gtagEvent("sign_up", { method: "Default" });
            if (response.loggedIn) {
              const getCustomerDataPromise = sessionApp.getCustomerData();
              getCustomerDataPromise.then((customerDataResp: any) => {
                loading.dismiss();
                storeX.commit("setCustomerData", customerDataResp);
                registerDevice();
                if (customerDataResp.account_data.phone_verified == 1) {
                  let referalUrl = getLocalStorageData("referralUrl");
                  if (referalUrl) {
                    const referalUrlArr =
                      getLocalStorageData("referralUrl").split("/");
                    const oldStoreCode = referalUrlArr[1];
                    if (referalUrlArr[1] != storeX.state.storeCode) {
                      referalUrlArr[1] = storeX.state.storeCode;
                      referalUrl =
                        referalUrlArr.join("/") + "?from_store=" + oldStoreCode;
                    }
                    router.replace(referalUrl);
                    setLocalStorageData({ referralUrl: null });
                  } else {
                    router.replace("/account");
                  }
                } else {
                  router.push("/account/mobile");
                }
              });
            } else {
              loading.dismiss();
              router.replace("/account");
            }
            let msg = t("successMsgs.successSignUp");
            if (response.needConfirmation) {
              msg = t("successMsgs.confirmationEmailRegister");
            }
            openToast(msg, "success");
          } else {
            loading.dismiss();
            storeX.commit("setCustomerData", null);
            if (te("apiMessages.codes." + response.error.code)) {
              openToast(
                t("apiMessages.codes." + response.error.code),
                "danger"
              );
            } else {
              te("setCustomerData", null);
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error);
        });
    };

    const navSignIn = () => {
      router.push("/account/login");
    };

    return {
      //ionViewWillEnter,
      t,
      navSignIn,
      onTelInput,
      icons,
      countryCode,
      //allowedCountries,
      signUp,
      vSignUp,
      signUpAction,
      whatsAppLink,
    };
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonCheckbox,
    VueTelInput,
    MainHeader,
    MainFooter,
  },
});
